import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectWithdraw } from '@app/store/wallet/selectors';
import { IWithdraw } from '@libs/interfaces';
import { addToast } from '@app/store/toast/actions';
import { IToast } from '@app/store/toast/model';
import { IModal } from '@app/store/modal/model';
import { addModal } from '@app/store/modal/actions';

@Injectable({
  providedIn: 'root',
})
export class StoreWalletService {
  constructor(private store: Store) {}

  selectWithdraw(): Observable<IWithdraw> {
    return this.store.select(selectWithdraw);
  }
  addToast(toast: IToast) {
    this.store.dispatch(addToast({ toast }));
  }
  addModal(modal: IModal) {
    this.store.dispatch(addModal({ modal }));
  }
}
